import { FontIcons } from '@brandfolder/react';
import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, { useEffect, FunctionComponent, useState, ReactNode } from 'react';

import { Asset, AssetTypes, Tag } from '@api/v4/assets/assetTypes';
import { FlattenedCustomFieldKeyValuesMap } from '@api/v4/assets/customFieldTypes';
import { get as getAttachment } from '@api/v4/attachments';
import { Attachment, isVideoType, PreviewType, AttachmentWithViewOnly } from '@api/v4/attachments/attachmentTypes';
import { AudioPreview } from '@components/asset/modal/tabs/asset_details/asset_preview/AudioPreview';
import { ExternalMediaPreview } from '@components/asset/modal/tabs/asset_details/asset_preview/ExternalMediaPreview';
import { ImagePreview } from '@components/asset/modal/tabs/asset_details/asset_preview/ImagePreview';
import { MuxVideoPreview } from '@components/asset/modal/tabs/asset_details/asset_preview/MuxVideoPreview';
import { PDFPreview } from '@components/asset/modal/tabs/asset_details/asset_preview/PDFPreview';
import { TextPreview } from '@components/asset/modal/tabs/asset_details/asset_preview/TextPreview';
import { AssetBackgroundColor } from '@components/asset/modal/tabs/asset_details/helpers/colors';
import FontClass from '@components/asset/shared/fontClass';
import { BFLoader } from '@components/common/loader/main';

import { ImageOverlay } from '@components/people_tags/common/ImageOverlay';

import { PeoplePageParamKeys } from '@components/people_tags/customPeopleTagTypes';

import { useCheckForFaces } from '@components/people_tags/fetch';

import { getEnableOpenInNewTab, getShowOpenInNewTab, OpenInNewTab } from '../OpenInNewTab';

interface AssetPreviewProps {
  asset: Asset;
  assetType: AssetTypes;
  attachmentKeys: string[];
  isDownloadable: boolean;
  isWorkspace: boolean;
  threeSixtyViewer: boolean;
  assetBackgroundColor?: AssetBackgroundColor;
  tags: Tag[];
  customFieldKeyValuesList: FlattenedCustomFieldKeyValuesMap,
}

type UpdateSelectedAttachmentEvent = Event & { detail: { attachmentKey: string } };
type AssetPreviewSignature = ((asset: Asset) => JSX.Element) | (() => JSX.Element);
type AttachmentPreviewSignature = ((attachment: AttachmentWithViewOnly) => JSX.Element) | (() => JSX.Element);

const AssetPreview: FunctionComponent<AssetPreviewProps> = (props) => {
  const {
    asset,
    assetBackgroundColor,
    assetType,
    attachmentKeys,
    customFieldKeyValuesList,
    isDownloadable,
    isWorkspace,
    threeSixtyViewer = false,
    tags
  } = props;

  const SAMSUNG_ORG_KEY = '275pfpp75hrnhkn5kjbmj87';
  const STAGE_ORG_KEY = "fk9gpv64fvk2k9r58gcf5pr";

  const [attachments, setAttachments] = useState<{ [attachmentKey: string]: Attachment }>({});
  const [isPreviewRenderError, setIsPreviewRenderError] = useState(false);
  const [selectedAttachmentKey, setSelectedAttachmentKey] = useState(attachmentKeys?.[0] || '');

  const selectedAttachment = attachments[selectedAttachmentKey];
  const { assetHasFaces } = useCheckForFaces(asset.asset_key, selectedAttachment?.extension);
  const previewType = selectedAttachment?.preview_type;

  const handleUpdateSelectedAttachment = (e: UpdateSelectedAttachmentEvent): void => (
    setSelectedAttachmentKey(e.detail.attachmentKey)
  );

  const is3dImage = (tagList: Tag[], extension: string): boolean => (
    !!tags.find(({ name }) => name.toLowerCase() === '3d') && (extension === 'usdz' || extension === 'glb')
  );

  const url3dImage = (customFields: FlattenedCustomFieldKeyValuesMap): string => {
    try {
      const embedLink = Object.entries(customFields).find((field) => field[1].customFieldKey.name.toLowerCase() === 'embed link');
      return embedLink[1].customFieldValues[0].value;
    } catch (e) {
      return '';
    }
  };

  const useConfigurableExpiryUrls = BFG.organization_key === SAMSUNG_ORG_KEY || BFG.organization_key === STAGE_ORG_KEY

  useEffect(() => {
    window.addEventListener('updateSelectedAttachment', handleUpdateSelectedAttachment);

    return (): void => {
      window.removeEventListener('updateSelectedAttachment', handleUpdateSelectedAttachment);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getAttachmentAsync = async (): Promise<void> => {
      // todo(emmi): include updateFetchControllers for attachment api wrappers
      if (!attachmentKeys?.length || selectedAttachment) return;
      // eslint-disable-next-line no-useless-catch
      try {
        let additionalAttachmentFields = 'cdn_url,mux_hls_url,preview_type,view_thumbnail_retina,is_processing';
        // just get the additional video fields up front since it's very cheap to grab them
        if (useConfigurableExpiryUrls) {
          additionalAttachmentFields += ',configurable_expiry_url,configurable_expiry_thumbnail_url,configurable_expiry_view_thumbnail_retina';
        }

        const attachment = await getAttachment(
          selectedAttachmentKey,
          {
            params: {
              fields: additionalAttachmentFields
            }
          }
        );

        setAttachments({
          ...attachments,
          [selectedAttachmentKey]: attachment.attributes
        });
      } catch (e) {
        throw e;
      }
    };

    getAttachmentAsync();
  }, [attachmentKeys, attachments, selectedAttachment, selectedAttachmentKey]);

  const showOpenInNewTab = getShowOpenInNewTab({
    asset,
    isDownloadable,
    isPreviewRenderError,
    selectedAttachment,
    threeSixtyViewer
  });

  const enableOpenInNewTab = getEnableOpenInNewTab({
    selectedAttachment,
    showOpenInNewTab
  });

  const renderPreviewInner = (): ReactNode => {
    const preview3dImage = (imageUrl: string): JSX.Element => (
      <iframe
        className="external-medium-preview"
        data-private
        data-testid="external-medium-preview--iframe"
        frameBorder="0"
        src={imageUrl}
        title={asset.name}
      />
    );

    const preview2dImage = (resource: Attachment | Asset): JSX.Element => (
      <ImagePreview
        assetType={assetType}
        isPreviewRenderError={isPreviewRenderError}
        resource={resource}
        setIsPreviewRenderError={setIsPreviewRenderError}
      />
    );

    const previewImage = (resource: Attachment | Asset): JSX.Element => {
      if (is3dImage(tags, resource.extension)) {
        const imageUrl = url3dImage(customFieldKeyValuesList);
        if (imageUrl) {
          return preview3dImage(imageUrl);
        }
      }
      return preview2dImage(resource);
    };

    const previewColor = (): JSX.Element => (
      <div className="color-preview" data-testid="asset-preview--color" style={assetBackgroundColor} />
    );

    const previewFont = (): JSX.Element => (
      <FontClass asset={asset}>
        Aa Bb Cc Dd Ee Ff Gg Hh Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz 0123456789
      </FontClass>
    );

    const attachmentPreviews: { [key: string]: AttachmentPreviewSignature } = {
      [PreviewType.Audio]: AudioPreview,
      [PreviewType.Image]: previewImage,
      [PreviewType.OfficeDoc]: previewImage,
      [PreviewType.PDF]: selectedAttachment?.is_processing ? previewImage : PDFPreview,
      [PreviewType.VideoMux]: MuxVideoPreview,
      [PreviewType.VideoOther]: MuxVideoPreview
    };

    const assetPreviews: { [key: string]: AssetPreviewSignature } = {
      // Assets with no attachments (and will never have any attachments)
      [AssetTypes.ExternalMedium]: ExternalMediaPreview,
      [AssetTypes.Text]: TextPreview,
      [AssetTypes.Color]: previewColor,
      [AssetTypes.Font]: previewFont,
      // Assets with no attachments (BUT are capable of having attachments)
      [AssetTypes.GenericFile]: previewImage,
      [AssetTypes.People]: previewImage,
      [AssetTypes.Press]: previewImage,
    };

    const attachmentsPresent = !threeSixtyViewer && !!attachmentKeys?.length && selectedAttachment;
    const noAttachmentsPresent = !threeSixtyViewer && !attachmentKeys?.length;
    const isLoading = !threeSixtyViewer && !!attachmentKeys?.length && !selectedAttachment;

    return (
      <>
        {/*
            External Media, Color, Font and Text assets will never have attachments
            Generic File, People and Press assets may have 0 or more attachments
          */}
        {threeSixtyViewer && <div data-testid="asset-preview--three-sixty-viewer" id="three-sixty-viewer" />}
        {attachmentsPresent && attachmentPreviews[selectedAttachment.preview_type]({...selectedAttachment, view_only: asset.view_only})}
        {noAttachmentsPresent && assetPreviews[assetType](asset)}
        {isLoading && <BFLoader />}
      </>
    );
  };

  const renderPreviewLinkWrapper = (): ReactNode => {
    if (!enableOpenInNewTab) return renderPreviewInner();

    return (
      <OpenInNewTab
        asset={asset}
        className="s-expand-view"
        isDownloadable={isDownloadable}
        isPreviewRenderError={isPreviewRenderError}
        selectedAttachment={selectedAttachment}
        selectedAttachmentKey={selectedAttachmentKey}
        threeSixtyViewer={threeSixtyViewer}
        useConfigurableExpiryUrls={useConfigurableExpiryUrls}
        isImage={true}
      >
        {renderPreviewInner()}
      </OpenInNewTab>
    );
  };

  const assetResourceImage = (
    <div
      className={classnames(
        'm-show-full-size-display asset-preview-wrapper',
        {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'no-link': !enableOpenInNewTab,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'responsive-height': assetType === AssetTypes.ExternalMedium,
          video: isVideoType(previewType)
        }
      )}
      data-private
      data-testid="asset-preview--image-container"
      style={assetBackgroundColor}
    >
      {renderPreviewLinkWrapper()}
      <OpenInNewTab
        asset={asset}
        className={classnames(
          'open-in-new-tab-button',
          isWorkspace && 'open-in-new-tab-button--is-task'
        )}
        data-testid="asset-preview--open-in-new-tab"
        isDownloadable={isDownloadable}
        isPreviewRenderError={isPreviewRenderError}
        selectedAttachment={selectedAttachment}
        selectedAttachmentKey={selectedAttachmentKey}
        threeSixtyViewer={threeSixtyViewer}
        useConfigurableExpiryUrls={useConfigurableExpiryUrls}
      />
    </div>
  );

  const showTagPeopleOverlay = BFG.context.hasFeature('people_tagging') && BFG.brandfolderSettings.people_tagging && assetHasFaces;

  return showTagPeopleOverlay ? (
    <ImageOverlay
      buttonText={t`Tag people`}
      href={`/${BFG.brandfolder_slug}/people_tags?${PeoplePageParamKeys.AssetIds}=${asset.asset_key}`}
      icon={FontIcons.Tag}
    >
      {assetResourceImage}
    </ImageOverlay>
  ) : assetResourceImage;
};

export default AssetPreview;
